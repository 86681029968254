import React from 'react'
import AliceCarousel from 'react-alice-carousel'


export default class Testimonials extends React.Component {
  render() {
    const clientsTestimonials = this.props.data
    const responsive = {
      1024: { items: 3 },
      768: { items: 2 },
      600: { items: 2 },
      0: { items: 1 }
    }

    return (
      <section className="section-clients-testimonials">
        <div className="container">
          <h2 className="extra-large">{clientsTestimonials.headline}</h2>

          <div className="row">
            {clientsTestimonials.clients.length > 3 && <ul className="prev-next">
              <li onClick={() => this.Carousel.slidePrev()} />
              <li onClick={() => this.Carousel.slideNext()} />
            </ul>}
            <AliceCarousel
              dotsDisabled={false}
              buttonsDisabled={false}
              responsive={responsive}
              ref={ el => this.Carousel = el }
              mouseDragEnabled >

              {clientsTestimonials.clients.map((item) => {
                return (
                  <div className="item" key={item.citation.citation}>
                    <div className="box-bloc">
                      <div className="box-logo">
                        {item.logo.file && <img src={item.logo.file.url} alt={item.citation.citation}/>}
                      </div>
                      <div className="testimonial" dangerouslySetInnerHTML={{ __html: item.citation.childMarkdownRemark.html }}/>
                      <div className="box-nom">
                        <p className="nom">{item.nom}</p>
                        <p className="poste">{item.poste}</p>
                      </div>
                    </div>
                  </div>
                )
              })}

            </AliceCarousel>
          </div>
        </div>
      </section>
    );
  }
}