import React from 'react'
import { Link } from 'gatsby'

function BlocImageTexte(props) {

  const blocImageTexte = props.data

  return (
    <section className="section-4" id={blocImageTexte.ancre}>
      <div className="container">
        <div className="row">
          <div className="columns col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
            <div className="box-item">
              <div>
                <h2 className="large text-left">{blocImageTexte.headline}</h2>
                <p className="text-left">{blocImageTexte.texte.texte}</p>
                {blocImageTexte.listePucesDeFeatures ? <p dangerouslySetInnerHTML={{ __html: blocImageTexte.listePucesDeFeatures.childMarkdownRemark.html }}/> : ''}
                {blocImageTexte.lienInterne ? <Link to={blocImageTexte.lienInterne}><button className="btn btn-secondary"><span>{blocImageTexte.lienTitreCta}</span></button></Link> : ''}
              </div>
            </div>
          </div>
          <div className="columns col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
            <div className="box-item">
              <div>
                <img src={blocImageTexte.image.file.url} className={blocImageTexte.image.contentful_id === '3X2LmAbmCA5q0TKDSjaoqK' ? 'decalage-gauche' : ''} alt={blocImageTexte.image.description}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlocImageTexte
